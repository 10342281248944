import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const SmartLink = ({ href = null, children = null }) => {
  console.assert(href !== null, "expected href to not be null");

  const external = /^https?:\/\/.*/i.test(href);

  if (external) {
    return (
      <OutboundLink href={href} target="_blank" rel="noreferrer noopener">
        {children}
      </OutboundLink>
    );
  }
  return <Link to={href}>{children}</Link>;
};
