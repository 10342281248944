import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Img from "gatsby-image";
import { SmartLink } from "./smart-link";

const StyledAppBar = styled(AppBar)`
  position: relative;
  border-radius: 4px 4px 0 0;
`;

const AppBarTitle = styled(Typography)`
  margin: 0;
`;

const StyledGridList = styled(GridList)`
  flex-wrap: nowrap;
  margin: 0 !important;
  &::-webkit-scrollbar {
    border-radius: ${(p) => p.theme.shape.borderRadius};
    height: ${(p) => p.theme.shape.borderRadius * 2};
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${(p) => p.theme.shape.borderRadius};
    border: 1px solid rgba(0, 0, 0, 0.47);
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const StyledGridListTile = styled(GridListTile)`
  width: 10rem;
`;

const StyledGridListTileBar = styled(GridListTileBar)`
  color: ${(p) => p.theme.palette.default};
  text-align: center;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const PreviewContainer = ({ title, content, classes, images }) => {
  const tiles = content.nodes;
  const imageMap =
    images &&
    images.nodes.reduce((map, node) => {
      return { ...map, [node.name]: node.childImageSharp };
    }, {});
  return (
    <Paper elevation={4}>
      <StyledAppBar position="static" color="default">
        <Toolbar>
          <AppBarTitle variant="h4" color="inherit">
            {title}
          </AppBarTitle>
        </Toolbar>
      </StyledAppBar>
      <StyledGridList cellHeight="auto">
        {tiles &&
          tiles.map((node) => (
            <SmartLink {...node} key={node.id}>
              <StyledGridListTile>
                {imageMap[node.image] && (
                  <Img fluid={imageMap[node.image].fluid} />
                )}
                <StyledGridListTileBar title={node.title} />
              </StyledGridListTile>
            </SmartLink>
          ))}
      </StyledGridList>
    </Paper>
  );
};

PreviewContainer.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object),
};

PreviewContainer.defaultProps = {
  content: [],
};

export default PreviewContainer;
