import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import PostsGrid from "./posts-grid";

const query = graphql`
  query {
    posts: allContentfulPost(limit: 4, sort: { fields: date, order: DESC }) {
      nodes {
        title
        summary
        tags
        date
        pathname
      }
    }
  }
`;

export default () => {
  const data = useStaticQuery(query);
  const {
    posts: { nodes },
  } = data;
  const reversed = [...nodes].reverse();
  return <PostsGrid posts={reversed} />;
};
