import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import PreviewContainer from "../components/preview-container";
import PostsGrid from "../components/posts-grid";

const query = graphql`
  {
    allWorkJson {
      nodes {
        id
        title
        image
        href
      }
    }
    companyLogos: allFile(
      filter: {
        relativePath: { regex: "/company-logos/" }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allProjectsJson {
      nodes {
        id
        title
        image
        href
      }
    }
    projectImages: allFile(
      filter: {
        relativePath: { regex: "/projects/" }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const {
    allWorkJson,
    companyLogos,
    allProjectsJson,
    projectImages,
  } = useStaticQuery(query);
  return (
    <Layout title="Bayan Bennett">
      <PostsGrid />
      <br />
      <PreviewContainer
        title="Projects"
        content={allProjectsJson}
        images={projectImages}
      />
      <br />
      <PreviewContainer
        title="Work"
        content={allWorkJson}
        images={companyLogos}
      />
    </Layout>
  );
};

export default IndexPage;
