import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";

import MoreIcon from "@material-ui/icons/MoreHoriz";
import Tooltip from "@material-ui/core/es/Tooltip/Tooltip";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardContent from "@material-ui/core/CardContent";
import CodeLogos from "../code-logos";
import CardActions from "@material-ui/core/CardActions";

const CellWidth = "(90vw + .2rem)";

const Root = styled.div`
  width: calc(${CellWidth});
  margin: 0 auto;
`;

const CardContent = styled(MuiCardContent)`
  padding: 5%;
`;

const GatsbyLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const CardActionArea = styled(MuiCardActionArea)`
  flex: 1;
  justify-content: center;
`;

const CellTitle = styled.div`
  font-family: ${(p) => p.theme.typography.h1.fontFamily};
  color: ${(p) => p.theme.palette.text.primary};
  font-size: calc(${(p) => p.size}vw * 0.618);
  @media (max-width: 680px) {
    font-size: calc(${(p) => p.size}vw);
  }
`;

const CellDate = styled(CellTitle)`
  font-size: calc(${(p) => p.size}vw / 2 * 0.618);
  @media (max-width: 680px) {
    font-size: calc(${(p) => p.size}vw / 2);
  }
`;

const CellContent = styled(Card)`
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  font-size: calc(${(p) => p.size}vw);
  height: calc(100% - 0.6rem);
  width: 100%;
  margin: 0.3rem;
`;

const Cell = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(${CellWidth} * ${(p) => p.width} / 13);
  height: calc(${CellWidth} * ${(p) => p.height} / 13);
  @media (max-width: 680px) {
    width: calc(${CellWidth} * ${(p) => p.height} / 8);
    height: calc(${CellWidth} * ${(p) => p.width} / 8);
  }
`;

const Row = styled(Cell)`
  flex-flow: row nowrap;
  @media (max-width: 680px) {
    flex-flow: column nowrap;
  }
`;

const Col = styled(Cell)`
  flex-flow: column nowrap;
  @media (max-width: 680px) {
    flex-flow: row nowrap;
  }
`;

const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

const PostsGrid = (props) => {
  const { large = 1, small = 1, children, posts } = props;
  const rowSize = large + small;
  if (large === 1)
    return (
      <PostsGrid large={rowSize + large} small={rowSize} posts={posts}>
        <Cell width={rowSize} height={large}>
          <GatsbyLink to="/posts" aria-label="More">
            <Tooltip title="More">
              <IconButton color="primary">
                <MoreIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </GatsbyLink>
        </Cell>
      </PostsGrid>
    );
  if (large < 13) {
    const passedPosts = [...posts];
    const [post1, post2] = passedPosts.splice(0, 2);
    return (
      <PostsGrid large={rowSize + large} small={rowSize} posts={passedPosts}>
        <Row width={rowSize} height={large}>
          <Cell width={large} height={large}>
            <CellContent size={large} raised={true}>
              <GatsbyLink to={`/posts/${post2.pathname}`}>
                <CardActionArea>
                  <CardContent>
                    <CellDate size={large}>{formatDate(post2.date)}</CellDate>
                    <CellTitle size={large}>{post2.title}</CellTitle>
                  </CardContent>
                </CardActionArea>
              </GatsbyLink>
              <CardActions disableSpacing={true}>
                <CodeLogos tags={post2.tags} size={large} />
              </CardActions>
            </CellContent>
          </Cell>
          <Col width={small} height={large}>
            <Cell width={small} height={small}>
              <CellContent size={small} raised={true}>
                <GatsbyLink to={`/posts/${post1.pathname}`}>
                  <CardActionArea>
                    <CardContent>
                      <CellDate size={small}>{formatDate(post1.date)}</CellDate>
                      <CellTitle size={small}>{post1.title}</CellTitle>
                    </CardContent>
                  </CardActionArea>
                </GatsbyLink>
                <CardActions disableSpacing={true}>
                  <CodeLogos tags={post1.tags} size={small} />
                </CardActions>
              </CellContent>
            </Cell>
            {children}
          </Col>
        </Row>
      </PostsGrid>
    );
  }
  if (large >= 13) return <Root>{children}</Root>;
};

export default PostsGrid;
